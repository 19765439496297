import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9c49d78c = () => interopDefault(import('../pages/360-view/index.vue' /* webpackChunkName: "pages/360-view/index" */))
const _f588268e = () => interopDefault(import('../pages/admins/index.vue' /* webpackChunkName: "pages/admins/index" */))
const _6dd35654 = () => interopDefault(import('../pages/audit-logs/index.vue' /* webpackChunkName: "pages/audit-logs/index" */))
const _047bed5f = () => interopDefault(import('../pages/bim-compare/index.vue' /* webpackChunkName: "pages/bim-compare/index" */))
const _42bb376c = () => interopDefault(import('../pages/bim-uploader/index.vue' /* webpackChunkName: "pages/bim-uploader/index" */))
const _59af51a2 = () => interopDefault(import('../pages/cameras/index.vue' /* webpackChunkName: "pages/cameras/index" */))
const _65b1015c = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _27b5758d = () => interopDefault(import('../pages/copilot/index.vue' /* webpackChunkName: "pages/copilot/index" */))
const _33434b31 = () => interopDefault(import('../pages/demos/index.vue' /* webpackChunkName: "pages/demos/index" */))
const _3c4918c0 = () => interopDefault(import('../pages/demos/index/cameras/_cameraId.vue' /* webpackChunkName: "pages/demos/index/cameras/_cameraId" */))
const _3b83ffec = () => interopDefault(import('../pages/demos/index/projects/_projectId.vue' /* webpackChunkName: "pages/demos/index/projects/_projectId" */))
const _537f9205 = () => interopDefault(import('../pages/demos/index/users/_user.vue' /* webpackChunkName: "pages/demos/index/users/_user" */))
const _09677f6d = () => interopDefault(import('../pages/duplicate-cameras/index.vue' /* webpackChunkName: "pages/duplicate-cameras/index" */))
const _690bd90a = () => interopDefault(import('../pages/emailing/index.vue' /* webpackChunkName: "pages/emailing/index" */))
const _c7405396 = () => interopDefault(import('../pages/ex-nvr-health-check/index.vue' /* webpackChunkName: "pages/ex-nvr-health-check/index" */))
const _3c9270b7 = () => interopDefault(import('../pages/gate-report/index.vue' /* webpackChunkName: "pages/gate-report/index" */))
const _6b09c7ce = () => interopDefault(import('../pages/ingest/index.vue' /* webpackChunkName: "pages/ingest/index" */))
const _ead8755c = () => interopDefault(import('../pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _2be8112b = () => interopDefault(import('../pages/map-view/index.vue' /* webpackChunkName: "pages/map-view/index" */))
const _0fc75dcd = () => interopDefault(import('../pages/media-hub/index.vue' /* webpackChunkName: "pages/media-hub/index" */))
const _250c62fe = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _3d712bfe = () => interopDefault(import('../pages/nvrs/index.vue' /* webpackChunkName: "pages/nvrs/index" */))
const _7b1b8bc5 = () => interopDefault(import('../pages/offline-cameras/index.vue' /* webpackChunkName: "pages/offline-cameras/index" */))
const _d6bd2734 = () => interopDefault(import('../pages/pa/index.vue' /* webpackChunkName: "pages/pa/index" */))
const _b78ac0bc = () => interopDefault(import('../pages/progress-photos/index.vue' /* webpackChunkName: "pages/progress-photos/index" */))
const _48a823a9 = () => interopDefault(import('../pages/progress-photos-history/index.vue' /* webpackChunkName: "pages/progress-photos-history/index" */))
const _34b577a2 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _5415448b = () => interopDefault(import('../pages/routers/index.vue' /* webpackChunkName: "pages/routers/index" */))
const _06d00fb2 = () => interopDefault(import('../pages/share-requests/index.vue' /* webpackChunkName: "pages/share-requests/index" */))
const _4fe22629 = () => interopDefault(import('../pages/shares/index.vue' /* webpackChunkName: "pages/shares/index" */))
const _63a68cb1 = () => interopDefault(import('../pages/sims/index.vue' /* webpackChunkName: "pages/sims/index" */))
const _663e5d96 = () => interopDefault(import('../pages/site-analytics/index.vue' /* webpackChunkName: "pages/site-analytics/index" */))
const _21c7bcf3 = () => interopDefault(import('../pages/site-analytics/index/_camera_exid.vue' /* webpackChunkName: "pages/site-analytics/index/_camera_exid" */))
const _4a3bdcec = () => interopDefault(import('../pages/site-view/index.vue' /* webpackChunkName: "pages/site-view/index" */))
const _fb1988ca = () => interopDefault(import('../pages/site-visit-cameras/index.vue' /* webpackChunkName: "pages/site-visit-cameras/index" */))
const _8730b750 = () => interopDefault(import('../pages/snapshot-extractions/index.vue' /* webpackChunkName: "pages/snapshot-extractions/index" */))
const _27f31c49 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _7980c33b = () => interopDefault(import('../pages/users/signin.vue' /* webpackChunkName: "pages/users/signin" */))
const _3dba0810 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67fa57d5 = () => interopDefault(import('../pages/360-view/_exid.vue' /* webpackChunkName: "pages/360-view/_exid" */))
const _8ac99e0c = () => interopDefault(import('../pages/bim-compare/_exid.vue' /* webpackChunkName: "pages/bim-compare/_exid" */))
const _8f5d6460 = () => interopDefault(import('../pages/bim-compare/_exid/compare.vue' /* webpackChunkName: "pages/bim-compare/_exid/compare" */))
const _f3b8a20a = () => interopDefault(import('../pages/bim-compare/_exid/ITWinBimCompare.vue' /* webpackChunkName: "pages/bim-compare/_exid/ITWinBimCompare" */))
const _1562d7a7 = () => interopDefault(import('../pages/bim-compare/_exid/layers.vue' /* webpackChunkName: "pages/bim-compare/_exid/layers" */))
const _4294429a = () => interopDefault(import('../pages/cameras/_id/index.vue' /* webpackChunkName: "pages/cameras/_id/index" */))
const _a19be372 = () => interopDefault(import('../pages/companies/_id/index.vue' /* webpackChunkName: "pages/companies/_id/index" */))
const _1a9c975c = () => interopDefault(import('../pages/gate-report/_exid.vue' /* webpackChunkName: "pages/gate-report/_exid" */))
const _7dcec9bc = () => interopDefault(import('../pages/gate-report/_exid/anpr.vue' /* webpackChunkName: "pages/gate-report/_exid/anpr" */))
const _3520acb4 = () => interopDefault(import('../pages/gate-report/_exid/events.vue' /* webpackChunkName: "pages/gate-report/_exid/events" */))
const _8568567a = () => interopDefault(import('../pages/gate-report/_exid/logs-history.vue' /* webpackChunkName: "pages/gate-report/_exid/logs-history" */))
const _02d62370 = () => interopDefault(import('../pages/gate-report/_exid/logs-history/verified-days.vue' /* webpackChunkName: "pages/gate-report/_exid/logs-history/verified-days" */))
const _747278da = () => interopDefault(import('../pages/gate-report/_exid/params.vue' /* webpackChunkName: "pages/gate-report/_exid/params" */))
const _acd50fe2 = () => interopDefault(import('../pages/gate-report/_exid/roi.vue' /* webpackChunkName: "pages/gate-report/_exid/roi" */))
const _7a5aa0cc = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _005b2087 = () => interopDefault(import('../pages/site-view/_exid.vue' /* webpackChunkName: "pages/site-view/_exid" */))
const _083a2a98 = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/360-view",
    component: _9c49d78c,
    name: "360-view"
  }, {
    path: "/admins",
    component: _f588268e,
    name: "admins"
  }, {
    path: "/audit-logs",
    component: _6dd35654,
    name: "audit-logs"
  }, {
    path: "/bim-compare",
    component: _047bed5f,
    name: "bim-compare"
  }, {
    path: "/bim-uploader",
    component: _42bb376c,
    name: "bim-uploader"
  }, {
    path: "/cameras",
    component: _59af51a2,
    name: "cameras"
  }, {
    path: "/companies",
    component: _65b1015c,
    name: "companies"
  }, {
    path: "/copilot",
    component: _27b5758d,
    name: "copilot"
  }, {
    path: "/demos",
    component: _33434b31,
    name: "demos",
    children: [{
      path: "cameras/:cameraId?",
      component: _3c4918c0,
      name: "demos-index-cameras-cameraId"
    }, {
      path: "projects/:projectId?",
      component: _3b83ffec,
      name: "demos-index-projects-projectId"
    }, {
      path: "users/:user?",
      component: _537f9205,
      name: "demos-index-users-user"
    }]
  }, {
    path: "/duplicate-cameras",
    component: _09677f6d,
    name: "duplicate-cameras"
  }, {
    path: "/emailing",
    component: _690bd90a,
    name: "emailing"
  }, {
    path: "/ex-nvr-health-check",
    component: _c7405396,
    name: "ex-nvr-health-check"
  }, {
    path: "/gate-report",
    component: _3c9270b7,
    name: "gate-report"
  }, {
    path: "/ingest",
    component: _6b09c7ce,
    name: "ingest"
  }, {
    path: "/kits",
    component: _ead8755c,
    name: "kits"
  }, {
    path: "/map-view",
    component: _2be8112b,
    name: "map-view"
  }, {
    path: "/media-hub",
    component: _0fc75dcd,
    name: "media-hub"
  }, {
    path: "/messages",
    component: _250c62fe,
    name: "messages"
  }, {
    path: "/nvrs",
    component: _3d712bfe,
    name: "nvrs"
  }, {
    path: "/offline-cameras",
    component: _7b1b8bc5,
    name: "offline-cameras"
  }, {
    path: "/pa",
    component: _d6bd2734,
    name: "pa"
  }, {
    path: "/progress-photos",
    component: _b78ac0bc,
    name: "progress-photos"
  }, {
    path: "/progress-photos-history",
    component: _48a823a9,
    name: "progress-photos-history"
  }, {
    path: "/projects",
    component: _34b577a2,
    name: "projects"
  }, {
    path: "/routers",
    component: _5415448b,
    name: "routers"
  }, {
    path: "/share-requests",
    component: _06d00fb2,
    name: "share-requests"
  }, {
    path: "/shares",
    component: _4fe22629,
    name: "shares"
  }, {
    path: "/sims",
    component: _63a68cb1,
    name: "sims"
  }, {
    path: "/site-analytics",
    component: _663e5d96,
    name: "site-analytics",
    children: [{
      path: ":camera_exid?",
      component: _21c7bcf3,
      name: "site-analytics-index-camera_exid"
    }]
  }, {
    path: "/site-view",
    component: _4a3bdcec,
    name: "site-view"
  }, {
    path: "/site-visit-cameras",
    component: _fb1988ca,
    name: "site-visit-cameras"
  }, {
    path: "/snapshot-extractions",
    component: _8730b750,
    name: "snapshot-extractions"
  }, {
    path: "/users",
    component: _27f31c49,
    name: "users"
  }, {
    path: "/users/signin",
    component: _7980c33b,
    name: "users-signin"
  }, {
    path: "/",
    component: _3dba0810,
    name: "index"
  }, {
    path: "/360-view/:exid?",
    component: _67fa57d5,
    name: "360-view-exid"
  }, {
    path: "/bim-compare/:exid?",
    component: _8ac99e0c,
    name: "bim-compare-exid",
    children: [{
      path: "compare",
      component: _8f5d6460,
      name: "bim-compare-exid-compare"
    }, {
      path: "ITWinBimCompare",
      component: _f3b8a20a,
      name: "bim-compare-exid-ITWinBimCompare"
    }, {
      path: "layers",
      component: _1562d7a7,
      name: "bim-compare-exid-layers"
    }]
  }, {
    path: "/cameras/:id",
    component: _4294429a,
    name: "cameras-id"
  }, {
    path: "/companies/:id",
    component: _a19be372,
    name: "companies-id"
  }, {
    path: "/gate-report/:exid?",
    component: _1a9c975c,
    name: "gate-report-exid",
    children: [{
      path: "anpr",
      component: _7dcec9bc,
      name: "gate-report-exid-anpr"
    }, {
      path: "events",
      component: _3520acb4,
      name: "gate-report-exid-events"
    }, {
      path: "logs-history",
      component: _8568567a,
      name: "gate-report-exid-logs-history",
      children: [{
        path: "verified-days",
        component: _02d62370,
        name: "gate-report-exid-logs-history-verified-days"
      }]
    }, {
      path: "params",
      component: _747278da,
      name: "gate-report-exid-params"
    }, {
      path: "roi",
      component: _acd50fe2,
      name: "gate-report-exid-roi"
    }]
  }, {
    path: "/projects/:id",
    component: _7a5aa0cc,
    name: "projects-id"
  }, {
    path: "/site-view/:exid?",
    component: _005b2087,
    name: "site-view-exid"
  }, {
    path: "/users/:id",
    component: _083a2a98,
    name: "users-id"
  }, {
    path: "/companies/:id/:project",
    component: _7a5aa0cc,
    name: "project"
  }, {
    path: "/companies/:id/:project/:camera",
    component: _4294429a,
    name: "camera"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
